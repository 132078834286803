<template>
  <div>
    <result-pay :url="url" business-type="kd"/>
  </div>
</template>

<script>
import ResultPay from './../page/result-pay';
import Api from './api';
export default {
  name: 'ResultPayVue',
  components: {
    ResultPay
  },
  data () {
    return {
      url: Api.PREFIX
    };
  },
  mounted () {},
  created () {},
  methods: {}
};
</script>
